body {
	text-rendering: optimizeLegibility;
	font: 1rem/1.3 sans-serif;
	color: #000000;
	word-break: break-word;
	font-kerning: normal;
	margin: 0;
}

::backdrop {
	background-color: rgba(0,0,0,0);
}

body:fullscreen {
	overflow-y: scroll !important;
}
body:-ms-fullscreen {
	overflow-y: scroll !important;
}
body:-webkit-full-screen {
	overflow-y: scroll !important;
}
body:-moz-full-screen {
	overflow-y: scroll !important;
}

#root {
	margin: 8px 8px 100vh 8px !important;
}

a {
	color: #000000;
	text-decoration: none;
	outline: none;
}

input {
	font-size: 1.05rem;
	background-color: transparent;
	border: 1px solid #828282;
	margin: 0.25rem;
	padding: 6px;
	border-radius: 4px;
}

.fullscreen {
	margin: 0.25rem;
	padding: 0.25rem;
}


pre {
	overflow: auto;
}

.container {
	margin: 1rem auto;
	max-width: 64rem;
}

.menu {
	font-size: 1.1rem;
	padding: 0 1rem;
}

.slogan {
	color: #828282;
}

.theme {
	float: right;
}

.item {
	display: table;
	color: #828282;
	margin-bottom: 0.7rem;
}

.item .source-logo {
	width: 0.9rem;
	height: 0.9rem;
}

.item a {
	color: #828282;
}
.item a:hover {
	text-decoration: underline;
}

.item a.link {
	font-size: 1.1rem;
	color: #000000;
}
.item a.link:visited {
	color: #828282;
}
.item a.link:hover {
	text-decoration: none;
}

span.source {
	margin-left: 0.4rem;
}

.item .info a.hot {
	color: #444444;
}

.article {
	padding-bottom: 3rem;
}

.article-container {
	margin: 1rem auto;
	max-width: 38rem;
}

.article a {
	border-bottom: 1px solid #222222;
}

.article h1 {
	font-size: 1.6rem;
}

.article h2 {
	font-size: 1.4rem;
}

.article h3, .article h4 {
	font-size: 1.3rem;
}

.article img {
	max-width: 100%;
	height: auto;
}

.article figure, .article video {
	width: 100%;
	height: auto;
	margin: 0;
}

.article table {
	width: 100%;
	table-layout: fixed;
}

.article iframe {
	display: none;
}

.article u {
	border-bottom: 1px solid #222;
	text-decoration: none;
}

.article .info {
	color: #828282;
}

.article .info a {
	border-bottom: none;
	color: #828282;
}
.article .info a:hover {
	text-decoration: underline;
}

.story-text {
	font: 1.2rem/1.5 'Apparatus SIL', sans-serif;
	margin-top: 1em;
}

.comments {
	margin-left: -1.25rem;
}

.comment {
	padding-left: 1.25rem;
}

.comment.lined {
	border-left: 1px solid #cccccc;
}

.comment .text {
	margin-top: -0.5rem;
}

.comment .text.hidden > p {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: none;
	color: #828282;
}

.comment .text.hidden > p:first-child {
	display: block;
}

.comment .collapser {
	padding-left: 0.5rem;
	padding-right: 1.5rem;
}

.comment .pointer {
	cursor: pointer;
}

.dot {
	cursor: pointer;
	position: fixed;
	height: 3rem;
	width: 3rem;
	background-color: #828282;
	border-radius: 50%;
}

.toggleDot {
	bottom: 1rem;
	left: 1rem;
}

.toggleDot .button {
	font: 2rem/1 'icomoon';
	position: relative;
	top: 0.5rem;
	left: 0.55rem;
}

.forwardDot {
	bottom: 1rem;
	right: 1rem;
}

.forwardDot .button {
	font: 2rem/1 'icomoon';
	position: relative;
	top: 0.5rem;
	left: 0.5rem;
}

.backwardDot {
	bottom: 1rem;
	right: 5rem;
}

.backwardDot .button {
	font: 2rem/1 'icomoon';
	position: relative;
	top: 0.5rem;
	left: 0.5rem;
}

.search form {
	display: inline;
}
